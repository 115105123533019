<template>
  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <ul class="nav">
      <li class="nav-item nav-profile">
        <router-link to="/admin-profile" class="nav-link">
          <div class="nav-profile-image">
                <img :src="$auth.user.profile_pic" alt="profile">
                <span class="login-status online"></span>
          </div>
          <div class="nav-profile-text d-flex flex-column">
            <span class="font-weight-bold mb-2">{{ $auth.user.name }}</span>
            <span class="text-secondary text-small">{{ $auth.user.role }}</span>
          </div>
          <i class="mdi mdi-bookmark-check text-success nav-profile-badge" v-if="$auth.user.is_client_master_user"></i>
        </router-link>
      </li>
      <li class="nav-item" :class="$route.name == 'home' ? 'active' : ''">
        <router-link to="/" class="nav-link">
          <i class="mdi mdi-home menu-icon"></i>
          <span class="menu-title">Dashboard</span>
        </router-link>
      </li>
      <li class="nav-item"
        :class="($route.name == 'opdslist' || $route.name == 'opdsadd' || $route.name == 'opddetails') ? 'active' : ''">
        <router-link to="/opdslist" class="nav-link">
          <i class="mdi mdi-book-multiple menu-icon"></i>
          <span class="menu-title">OPDS</span>
        </router-link>
      </li>
      <li class="nav-item"
        :class="($route.name == 'ipdslist' || $route.name == 'ipdsadd' || $route.name == 'ipddetails') ? 'active' : ''">
        <router-link to="/ipdslist" class="nav-link">
          <i class="mdi mdi-book-open-page-variant menu-icon"></i>
          <span class="menu-title">IPDS</span>
        </router-link>
      </li>
      <li class="nav-item"
        :class="($route.name == 'appointment' || this.$route.name == 'appointmentdetails') ? 'active' : ''">
        <router-link to="/appointment" class="nav-link">
          <i class="mdi mdi-book-plus menu-icon"></i>
          <span class="menu-title">Appointment</span>
        </router-link>
      </li>
      <li class="nav-item"
        :class="($route.name == 'billings') ? 'active' : ''">
        <router-link to="/billings" class="nav-link">
          <i class="mdi mdi-cash-multiple menu-icon"></i>
          <span class="menu-title">Billing</span>
        </router-link>
      </li>
      <li class="nav-item"
        :class="(this.$route.name == 'patients' || $route.name == 'opdsadd' || $route.name == 'patientsdetails') ? 'active' : ''">
        <router-link to="/patients" class="nav-link">
          <i class="mdi mdi-account-multiple menu-icon"></i>
          <span class="menu-title">Patients</span>
        </router-link>
      </li>
      <li class="nav-item"
        :class="(this.$route.name == 'medicines' || this.$route.name == 'addmedicines') ? 'active' : ''">
        <router-link to="/medicines" class="nav-link">
          <i class="mdi mdi-pill menu-icon"></i>
          <span class="menu-title">Medicines</span>
        </router-link>
      </li>
      <li class="nav-item" :class="this.$route.name == 'rooms-wards' ? 'active' : ''">
        <router-link to="/rooms-wards" class="nav-link">
          <i class="mdi mdi-fan menu-icon"></i>
          <span class="menu-title">Rooms/Ward</span>
        </router-link>
      </li>
      <li class="nav-item"
        :class="(this.$route.name == 'departmentlist' || this.$route.name == 'departmentdetails') ? 'active' : ''">
        <router-link to="/department" class="nav-link">
          <i class="mdi mdi-view-grid menu-icon"></i>
          <span class="menu-title">Department Management</span>
        </router-link>
      </li>
      <li class="nav-item"
        :class="(this.$route.name == 'reportslist' || this.$route.name == 'reportdetails') ? 'active' : ''">
        <router-link to="/reports" class="nav-link">
          <i class="mdi mdi-test-tube menu-icon"></i>
          <span class="menu-title">Reports Management</span>
        </router-link>
      </li>
      <li class="nav-item"
        :class="(this.$route.name == 'effectslist' || this.$route.name == 'effectdetails') ? 'active' : ''">
        <router-link to="/effects" class="nav-link">
          <i class="mdi mdi-apps menu-icon"></i>
          <span class="menu-title">Effects Management</span>
        </router-link>
      </li>
      <li class="nav-item"
        :class="(this.$route.name == 'cities' || this.$route.name == 'addcity' || this.$route.name == 'editcity') ? 'active' : ''">
        <router-link to="/cities" class="nav-link">
          <i class="mdi mdi-view-list menu-icon"></i>
          <span class="menu-title">Cities</span>
        </router-link>
      </li>
      <li class="nav-item" :class="this.$route.name == 'statistics' || this.$route.name == 'statistics_type_selection' ? 'active' : ''">
        <router-link to="/statistics" class="nav-link">
          <i class="mdi mdi-chart-areaspline menu-icon"></i>
          <span class="menu-title">Statistics</span>
        </router-link>
      </li>
      <li class="nav-item"
        :class="($route.name == 'doctor' || $route.name == 'adddoctor' || $route.name == 'editdoctor') ? 'active' : ''">
        <router-link to="/doctor" class="nav-link">
          <i class="mdi mdi-account-plus menu-icon"></i>
          <span class="menu-title">User Management</span>
        </router-link>
      </li>
      <li class="nav-item" :class="$route.name == 'settings' ? 'active' : ''">
        <router-link to="/settings" class="nav-link">
          <i class="mdi mdi-settings menu-icon"></i>
          <span class="menu-title">Settings</span>
        </router-link>
      </li>
      <li class="nav-item" :class="$route.name == 'contact-us-request' ? 'active' : ''">
        <router-link to="/contact-us-request" class="nav-link">
          <i class="mdi mdi-comment-processing menu-icon"></i>
          <span class="menu-title">Contact Us Request</span>
        </router-link>
      </li>
      <li class="nav-item" :class="this.$route.name == 'changelog' ? 'active' : ''">
        <router-link to="/changelog" class="nav-link">
          <i class="mdi mdi-pencil-box-outline menu-icon"></i>
          <span class="menu-title">Changelog</span>
        </router-link>
      </li>
      <li class="nav-item nav-logout d-lg-none">
        <a class="nav-link" href="javascript:void(0)" @click="LogOut()">
          <i class="mdi mdi-power menu-icon"></i> Logout
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>

import { mapActions, mapGetters } from "vuex";
import Cookies from 'js-cookie';

export default {

    mounted() {
      const script = document.createElement('script');
      script.src = "/assets/js/hoverable-collapse.js";
      document.head.appendChild(script);
    },

  methods:{
    ...mapActions("hospital",["logoutAdmin"]),


    LogOut() {
      if (confirm("Are you sure you want to logout?")) {
        const storedDeviceId = Cookies.get('device_id');
        let logoutData = {
          device_id: storedDeviceId,
        };
        this.logoutAdmin(logoutData).then((response) => {
            if (response.response_code == 200) {
                let logoutData = {
                  user_id: this.$auth.user.user_id,
                  devicetoken: this.$auth.user.token,
                  device_id: storedDeviceId,
                }
                this.$auth.logout(logoutData,(callback)=>{
                  this.$router.push({ name: callback }).catch(()=>{});
                });
            }else{
                this.$toasted.error(response.message, {duration: 2000,});
            }
        });
      }
    },
 }
}
</script>